<template>
  <section class="l-content">
    <el-card>
      <el-form :inline="true" :model="condition">
        <el-form-item label="所属部门">
          <l-select v-model="condition.departId" :remote="$api.departList" @change="getStatisticData()"></l-select>
        </el-form-item>
        <el-form-item label="日报日期">
          <l-datepicker type="daterange" :default="[condition.startDate, condition.endDate]" @change="dataRangeChange"></l-datepicker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getStatisticData()">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-divider> </el-divider>
    <el-card v-if="statisticData && tableOption">
      <el-table :data="statisticData.list" border style="width: 100%" :height="tableOption.height" :max-height="tableOption.maxHeight" :stripe="tableOption.stripe">
        <el-table-column prop="userName" align="center" label="员工姓名" width="180"> </el-table-column>
        <el-table-column v-for="(item, index) in statisticData.dayList" :key="index" align="center" prop="item" :label="item">
          <template slot-scope="scope">
            <p v-if="scope.row[item]">
              <el-button
                v-if="scope.row[item].reportState == 1"
                type="danger"
                icon="el-icon-question"
                size="mini"
                circle
                @click="$site.view_virusdailyreport_detail(scope.row[item].reportId)"
              ></el-button>
              <el-button v-else type="success" icon="el-icon-check" size="mini" circle @click="$site.view_virusdailyreport_detail(scope.row[item].reportId)"></el-button>
            </p>
            <p v-else>
              <el-button type="info" size="mini" circle>---</el-button>
            </p>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'MyCarBorrowList',
  data() {
    return {
      tableOption: null,
      tableOption22: {
        stripe: true,
        height: document.documentElement.clientHeight - this.$heightOffset1 + 'px',
        columns: [
          { label: '序号', type: 'index' },
          { label: '日期', slot: 'reportDate' },
          { label: '姓名', prop: 'userName' },
          { label: '电话', prop: 'userMobile' },
          { label: '部门', prop: 'departName' },
          { label: '是否发热咳嗽', slot: 'frksFlag' },
          { label: '是否上班', slot: 'workFlag' },
          { label: '体温', prop: 'temp' }
        ],
        operation: {
          width: '160',
          buttons: [{ type: 'primary', icon: 'el-icon-printer', label: '详细信息', click: this.detail }]
        }
      },
      condition: {
        keyword: '',
        // enterpriseCode: this.userInfo.enterpriseCode,
        startDate: this.$lgh.getDate(-7),
        endDate: this.$lgh.getDate(),
        pageIndex: 1,
        pageSize: 10
      },
      statisticData: null
    }
  },
  created() {
    this.getStatisticData()
  },
  methods: {
    dataRangeChange(d1, d2) {
      this.condition.startDate = d1
      this.condition.endDate = d2
    },
    async getStatisticData() {
      this.statisticData = await this.$lgh.get('api/enterprise/virusdailyreport/statistic', ['enterpriseCode', this.condition])

      var columns = [
        { label: '序号', type: 'index' },
        { label: '员工姓名', prop: 'userName' }
      ]

      this.statisticData.dayList.forEach(a => {
        columns.push({ label: a, prop: a })
      })

      this.tableOption = {
        stripe: true,
        height: document.documentElement.clientHeight - this.$heightOffset1 + 'px',
        columns: columns
      }
    },
    detail(item) {
      this.$site.view_virusdailyreport_detail(item.reportId)
    }
  }
}
</script>
